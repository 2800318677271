import React, { useRef } from 'react';
import styles from './RegisterDashboard.module.css'; // Assumindo que seus estilos estão em um arquivo CSS module
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';


const FormFaturas = () => {
  const formRef = useRef(null);
  const history = useHistory(); // Substitua useNavigate por useHistory

  const handleSubmit = async (event) => {
    event.preventDefault(); // Previne o comportamento padrão de recarregar a página

    // Coletando os dados do formulário
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);

    // Opcional: Mostrar os dados coletados no console
    // console.log(data);

    try {
      // Enviando os dados para a API
      const response = await fetch('https://carinapaga.com.br/api/register', { // Substitua com a URL da sua API
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Verifica se a requisição foi bem-sucedida
      if (response.ok) {
        // Limpar o formulário
        if (formRef.current) {
          formRef.current.reset();
        }
        // Mostrar notificação de sucesso
        toast.success('Você foi registrado com sucesso em alguns segundos, você será redirecionado!');

        setTimeout(() => {
          history.push('/login'); // Use history.push em vez de navigate
        }, 5000);
      } else {
        const errorData = await response.json();
        console.error('Erro na requisição:', errorData.error);

        // Mostrar notificação de erro específica
        toast.error(`${errorData.error}`);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      // Mostrar notificação de erro
      toast.error('Erro ao enviar os dados.');
    }
  };

  return (
    <div className="col-md-6 xs-hide">
      <div className={styles['form-container']}>
        <form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
          <div className={styles.formgroup}>
            <span>Preencha os campos abaixo para criar sua conta.</span>
            
            <div className={styles.inputgroup}>
              <div className={styles['input-container']}>
                <input name="firstName" placeholder="Nome" id="firstName" className={styles.input} required />
              </div>
              <div className={styles['input-container']}>
                <input name="lastName" placeholder="Sobrenome" id="lastName" className={styles.input} required />
              </div>
              <div className={styles['input-container']}>
                <input name="dob" placeholder="Data de Nascimento" id="dob" className={styles.input} type="text" required />
              </div>
              <div className={styles['input-container']}>
                <input name="phone" placeholder="Telefone com DDD" id="phone" className={styles.input} required />
              </div>
              <div className={styles['input-container']}>
                <input name="cpfcnpj" placeholder="CPF/CNPJ" id="cpfcnpj" className={styles.input} required />
              </div>
              <div className={styles['input-container']}>
                <input name="email" placeholder="Email" id="email" className={styles.input} type="email" required />
              </div>
              <div className={styles['input-container']}>
                <input name="password" placeholder="Senha" id="password" className={styles.input} type="password" required />
              </div>
              <div className={styles['input-container']}>
                <input name="confirmPassword" placeholder="Confirmar Senha" id="confirmPassword" className={styles.input} type="password" required />
              </div>
            </div>
            
            <div className={styles.terms}>
              <input type="checkbox" id="terms" name="terms" required style={{marginRight: '0.5rem'}}/>
              <label htmlFor="terms">Aceito os <a href="/termos.pdf" className={styles.registerLink}>termos e condições</a></label>
            </div>

            <button type="submit" className={styles.button} data-cy="register-button" style={{ paddingRight: '8rem', paddingLeft: '8rem' }}>
              REGISTRAR
            </button>
          </div>

          <p className={styles.agreementtext}>
            Já tem uma conta? <a href="/login" className={styles.registerLink}>Faça login aqui</a>.
          </p>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}

export default FormFaturas;
