import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button'; // Certifique-se de que @mui/material está instalado
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './AppDash.module.css'; // Assumindo que você tem um arquivo de estilos CSS Module

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation(); // Obtém a URL atual
  const [submenuVisible, setSubmenuVisible] = useState({
    users: false,
    payments: false,
    reports: false,
    settings: false
  });

  const toggleSubmenu = (menu) => {
    setSubmenuVisible(prevState => ({
      ...prevState,
      [menu]: !prevState[menu]
    }));
  };

  const handleLogout = async () => {
    try {
      await fetch('https://carinapaga.com.br/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('cpfcnpj'); // Remove o CPF/CNPJ do localStorage
      history.push('/login'); // Redireciona para a página de login
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const HomeApp = () => {
    history.push('/app');
  };

  const SimulatePayment = () => {
    history.push('/simulatepayment');
  };

  const isSimulatePaymentPage = location.pathname === '/simulatepayment'; // Verifica se está na página /simulatepayment

  return (
    <aside className={styles.sidebar}>
      <h1>CarinaPaga</h1>
      <ul>
        <li>
          <Button
            style={{
              background: 'rgb(255, 255, 255)',
              color: '#000',
              border: '1px solid #e63888',
              borderRadius: '5px',
              fontSize: '0.8rem',
              padding: '0.5rem 1rem',
              height: 'auto',
              letterSpacing: '1px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            startIcon={<HomeIcon />}
            onClick={HomeApp}
          >
            Home
          </Button>
        </li>
        <li>
          <Button
            style={{
              background: 'rgb(255, 255, 255)',
              color: '#000',
              border: '1px solid #e63888',
              borderRadius: '5px',
              fontSize: '0.8rem',
              padding: '0.5rem 1rem',
              height: 'auto',
              letterSpacing: '1px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
            startIcon={<ShoppingCartIcon />}
            onClick={() => toggleSubmenu('payments')}
          >
            Pagamentos
            {submenuVisible.payments ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          {submenuVisible.payments && (
            <ul className={styles.submenu}>
              <li>
                <Button>Pagamentos de Débitos Veiculares</Button>
              </li>
              <li>
                <Button>Pagamentos de Boletos</Button>
              </li>
              <li>
                <Button>Pagamentos de QR Code PIX</Button>
              </li>
              <li>
                <Button
                  className={isSimulatePaymentPage ? styles.activeButton : styles.inactiveButton} onClick={SimulatePayment}
                >
                  Simular Pagamentos
                </Button>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Button
            style={{
              background: 'rgb(255, 255, 255)',
              color: '#000',
              border: '1px solid #e63888',
              borderRadius: '5px',
              fontSize: '0.8rem',
              padding: '0.5rem 1rem',
              height: 'auto',
              letterSpacing: '1px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
            startIcon={<ChecklistRtlIcon />}
            onClick={() => toggleSubmenu('reports')}
          >
            Relatórios
            {submenuVisible.reports ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          {submenuVisible.reports && (
            <ul className={styles.submenu}>
              <li>
                <Button>Transações</Button>
              </li>
              <li>
                <Button>Pagamentos</Button>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Button
            style={{
              background: 'rgb(255, 255, 255)',
              color: '#000',
              border: '1px solid #e63888',
              borderRadius: '5px',
              fontSize: '0.8rem',
              padding: '0.5rem 1rem',
              height: 'auto',
              letterSpacing: '1px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            startIcon={<SendToMobileIcon />}
          >
            Recarga de Celular
          </Button>
        </li>
        <li>
          <Button
            style={{
              background: 'rgb(255, 255, 255)',
              color: '#000',
              border: '1px solid #e63888',
              borderRadius: '5px',
              fontSize: '0.8rem',
              padding: '0.5rem 1rem',
              height: 'auto',
              letterSpacing: '1px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
            startIcon={<SettingsIcon />}
            onClick={() => toggleSubmenu('settings')}
          >
            Configurações
            {submenuVisible.settings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          {submenuVisible.settings && (
            <ul className={styles.submenu}>
              <li>
                <Button>Gestão da Conta</Button>
              </li>
              <li>
                <Button>Gestão de Crédito</Button>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Button
            style={{
              background: 'rgb(255, 255, 255)',
              color: '#000',
              border: '1px solid #e63888',
              borderRadius: '5px',
              fontSize: '0.8rem',
              padding: '0.5rem 1rem',
              height: 'auto',
              letterSpacing: '1px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
          >
            Sair
          </Button>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
