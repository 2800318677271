import React, { useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import styles from './AppDash.module.css';
import Sidebar from './Sidebar';

const FormFaturas = () => {
  // eslint-disable-next-line
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [userData, setUserData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');

    if (!storedCpfCnpj) {
      // Se não há CPF/CNPJ no localStorage, redireciona para a página de login
      history.push('/login');
    } else {
      setCpfCnpj(storedCpfCnpj); // Recupera o CPF/CNPJ do localStorage
      fetchUserData(storedCpfCnpj); // Chama a API para coletar os dados do usuário
    }
  }, [history]);

  const fetchUserData = async (cpfcnpj) => {
    try {
      const response = await fetch(`https://carinapaga.com.br/api/user/${cpfcnpj}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Adicione outros headers necessários aqui
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar os dados do usuário');
      }

      const data = await response.json();
      const NomeCompleto = data.firstName + " " + data.lastName;
      setUserData(NomeCompleto);
    } catch (error) {
      console.error('Erro ao buscar os dados do usuário:', error);
    }
  };

  const getSaudacao = () => {
    const horas = new Date().getHours();
    if (horas < 12) {
      return 'Bom dia';
    } else if (horas < 18) {
      return 'Boa tarde';
    } else {
      return 'Boa noite';
    }
  };

  const capitalizeName = (name) => {
    return name
      .split(' ') // Divide o nome em partes (primeiro nome, sobrenome, etc.)
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()) // Capitaliza a primeira letra e coloca o resto em minúsculo
      .join(' '); // Junta as partes de volta em uma única string
  };

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar/>
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
              <h2>{getSaudacao()}, {userData ? capitalizeName(`${userData}`) : 'Usuário'}! 👋 Bem-vindo à CarinaPaga!<br></br></h2>
                <p>
                Na CarinaPaga, oferecemos uma plataforma completa para facilitar a gestão de pagamentos de faturas. Aqui, você pode acompanhar, parcelar e quitar seus débitos com total segurança e praticidade. Além disso, você pode gerenciar o pagamento de faturas de boletos, água, energia e outros serviços essenciais, tudo em um só lugar, garantindo mais conveniência e controle sobre suas finanças.
                </p>
              </div>
              <div className={styles.card}>
                <h2>Ações rápidas para a sua conta</h2>
                <p>
                  Resolva suas pendências de forma prática e segura com a CarinaPaga. Gerencie débitos e faturas em poucos cliques.
                  Use as ações rápidas abaixo para começar agora!
                </p>
                <div className={styles.buttonGroup}>
                  <button>Débitos Veiculares</button>
                  <button>Pagamentos de Boletos</button>
                  <button>Pagamentos via QR Code PIX</button>
                  <button>Recarga de Celular</button>
                  <button>Simular Pagamentos</button>
                  <button>Últimos Pagamentos</button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FormFaturas;
